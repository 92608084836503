<template>
  <v-progress-circular indeterminate color="primary"></v-progress-circular>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped></style>
